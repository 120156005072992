import React from 'react';
import SEO from '../../components/seo';


function ProjectManager() {
  return (
    <>
      <SEO title="Technical Project Manager" />
      <div className="container-fluid display-6 mb-6">
        <h1 className="font-weight-bolder underscore">Technical Project Manager</h1>
        <p>
        We’re looking for a full-time Technical Project Manager to manage our products, grants and the various client projects we have in numerous industries.
        </p>
        <p>
        Artlimes is an Innovation Technology company building state-of-the-art solutions that connect humans with technology.
        </p>
        <p>
        We are sector-agnostic and use AI, Machine Learning and the latest integration patterns to revolutionise industries that benefit from advancements in technology. Our real-time solutions help minimise human intervention and reduce the risk of failure due to human error in Shipping, Healthcare, Manufacturing, Transport and beyond.
        </p>
        <b>Responsibilities</b>
        <ul>
          <li>End-to-end management of multiple technical projects</li>
          <li>Grants and Procurement</li>
          <li>Liaise with business and clients</li>
          <li>Organise multiple teams and deliveries</li>
          <li>Reporting & Product Analytics</li>
          <li>Release management</li>
          <li>Program development</li>
        </ul>

        <b>About You</b>
        <ul>
          <li>You are proactive</li>
          <li>You are dedicated</li>
          <li>You are used to working in a fast-paced environment</li>
          <li>You are able to understand, organise, plan and execute technical requirements</li>
          <li>You have an excellent understanding of the latest technologies used online, but also offline</li>
          <li>You have proven experience in managing and delivering IoT and web technologies</li>
          <li>You understand what needs to go into delivering AI products</li>
          <li>You must have excellent communication, writing and presentational skills in both Greek and English</li>
          <li>You must have a good team spirit and be able motivate and mentor other members of your team</li>
        </ul>

        <b>We require all applicants to have the following:</b>
        <ul>
          <li>A Master’s degree in Computer Science or similar</li>
          <li>Proficiency in English</li>
          <li>Expert knowledge of the Atlassian ecosystem (JIRA, Confluence), including workflows, versions, administration and reporting</li>
          <li>Proven experience in managing multiple technical teams</li>
          <li>Proven end-to-end experience in delivering technical projects in Agile</li>
          <li>Knowledge of agile, lean, kanban, scrum methodologies</li>

        </ul>
        <b>You’ll get benefits</b>
        <p>For all our members at Artlimes we offer:</p>
        <ul>
          <li>Xmas/Easter/Summer salary contributions</li>
          <li>IKA insurance</li>
          <li>Paid Holidays</li>
          <li>Sick Pay</li>
          <li>Stock options</li>
          <li>Quick salary increase based on performance</li>
          <li>All your usual beverages, fruits and meals</li>
        </ul>
        <p>Apply if you are looking for great career prospects with one of the most innovative companies in the field of technology.</p>
        <a className="btn btn-info" href="https://forms.gle/qf3BxRFJxgwP6Dsm9" target="_blank">Apply now </a>
      </div>
    </>
  );
}

export default ProjectManager;
